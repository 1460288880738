import React from "react";
// import "../css/ConfirmationDialog.css";
import "../css/ErrorDialog.css";

function ErrorDialog({
	open,
	onClose,
	dialogText,
	dialogText2,
	confirmLabel = "Continue",
	confirmAction,
	cancelLabel = "Cancel",
	cancelAction,
	leftButtonLabel,
	leftButtonLink,
}) {
	return (
		<>
			{open && (
				<div
					className="custom-dialog error-custom-dialog-z"
					onClick={(e) =>
						e.target.className === "custom-dialog" && onClose()
					}
				>
					<div className="custom-dialog-content custom-confirmation-dialog-content error-dialog-content">
						{dialogText && (
							<div className="header">{dialogText}</div>
						)}
						{dialogText2 && (
							<div className="desc">{dialogText2}</div>
						)}

						<div className="confirmation-buttons">
							{/* Left Button */}
							{leftButtonLabel && leftButtonLink && (
								<button
									className="left_btn"
									onClick={() => {
										window.location.href = leftButtonLink; // Redirects to the link
									}}
								>
									{leftButtonLabel}
								</button>
							)}

							{/* Cancel Button */}
							{cancelLabel && (
								<button
									className="cancel_btn"
									onClick={cancelAction}
								>
									{cancelLabel}
								</button>
							)}

							{/* Confirm Button */}
							<button
								className="confirm_btn"
								onClick={confirmAction}
							>
								{confirmLabel}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ErrorDialog;
