import { useEffect, useState, useCallback, useRef } from "react";
import { axios } from "../../libs/axios"
import { useNavigate } from "react-router-dom";
import WeblManager from "./WeblManager";
import WeblContent from "./WeblContent";
import RestrictedWebl from "./RestrictedWebl";
import "../../css/Webl/WeblContent.css";
import "../../css/Webl/loading-bar.css";
import {
	createSession,
	getRangeValue,
	setCellValue,
	getChartImage,
	getChartNames,
	keepSessionAlive,
	deleteFile,
	closeSession,
} from "../../functions/spreadsheetFunctions";
import { cleanDropdowns, numberToCol } from "../../functions/WeblFunctions";
import ReactDOM from "react-dom";
import { GraphError } from "@microsoft/microsoft-graph-client";
import { useDomain } from "../../context/DomainContext";

function Webl({ weblData, weblid, weblTitle, user, previewMode }) {
	const navigate = useNavigate();
	const [currentUserIsTheOwner, setCurrentUserIsTheOwner] = useState(false);
	const [tookScreenshot, setTookScreenshot] = useState(false);
	const [downloadableSpreadsheet, setDownloadableSpreadsheet] =
		useState(false);
	const [peristentChanges, setPersistentChanges] = useState(null); // prettier-ignore
	const [centeredLayout, setCenteredLayout] = useState(false); // prettier-ignore
	const [realTimeSwitch, setRealTimeSwitch] = useState(); // prettier-ignore
	const [boxedContainer, setBoxedContainer] = useState(); // prettier-ignore
	const [customScrollbar, setCustomScrollbar] = useState(); // prettier-ignore
	const [fitWidthSwitch, setFitWidthSwitch] = useState();
	const [hideEmptySwitch, setHideEmptySwitch] = useState();
	const [selectedElementsRange, setSelectedElementsRange] = useState(null);
	const [initialElementsRange, setInitialElementsRange] = useState(null);
	const [sessionInfo, setSessionInfo] = useState(null);
	const [sheetNames, setSheetNames] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [sheetsVisibility, setSheetsVisibility] = useState([]);
	const [sheetToShow, setSheetToShow] = useState(null);
	const [weblValuesReady, setWeblValuesReady] = useState(false);
	const [spreadsheetHtml, setSpreadsheetHtml] = useState(null);
	const [weblToDelete, setWeblToDelete] = useState(0);
	const [loading, setLoading] = useState(false);
	const [weblTheme, setWeblTheme] = useState("Initial");
	const [inputFocusValue, setInputFocusValue] = useState();
	const inputFocusValueRef = useRef(inputFocusValue);
	const [removeIframeCode, setRemoveIframeCode] = useState(null);
	const [initialThemeSettings, setInitialThemeSettings] = useState({
		inputBorders: false,
		tableSpacing: false,
		roundBorders: false,
		gridlines: false,
	});
	const [dropdowns, setDropdowns] = useState();
	const [iframeComponent, setIframeComponent] = useState("");
	const [publicAccess, setPublicAccess] = useState("published");
	const [sheetNamesDictionary, setSheetNamesDictionary] = useState([]);
	const [zoomWebl, setZoomWebl] = useState(100);
	const containerRef = useRef(null);
	const [validNavigation, setValidNavigation] = useState(0);
	const manuallyChangedCell = useRef({ inputId: null, sheet: null });
	const { baseUrl, subdomain, rootHost } = useDomain();

	const endSession = async () => {
		try {
			await axios.post(baseUrl + "/api/webl/" + weblid + "/deleteWeblPreview");
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const handleUnload = () => {
			if (validNavigation === 0) {
				endSession();
			}
		};

		const handleKeyDown = (e) => {
			const key = e.which || e.keyCode;
			if (key === 116) {
				setValidNavigation(1);
			}
		};

		const handleLinkClick = () => {
			setValidNavigation(1);
		};

		const handleFormSubmit = () => {
			setValidNavigation(1);
		};

		window.addEventListener("unload", handleUnload);
		document.addEventListener("keydown", handleKeyDown);
		document.querySelectorAll("a").forEach((link) => {
			link.addEventListener("click", handleLinkClick);
		});
		document.querySelectorAll("form").forEach((form) => {
			form.addEventListener("submit", handleFormSubmit);
		});

		// Cleanup event listeners on component unmount
		return () => {
			window.removeEventListener("unload", handleUnload);
			document.removeEventListener("keydown", handleKeyDown);
			document.querySelectorAll("a").forEach((link) => {
				link.removeEventListener("click", handleLinkClick);
			});
			document.querySelectorAll("form").forEach((form) => {
				form.removeEventListener("submit", handleFormSubmit);
			});
		}; // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validNavigation]);

	/**
	 * Redirect a user to the Home page if he tries to access a Webl
	 * which is not public and he is not the owner.
	 */
	useEffect(() => {
		if (
			!currentUserIsTheOwner &&
			publicAccess !== "published" &&
			window.top === window
		) {
			navigate(baseUrl);
			return;
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publicAccess, currentUserIsTheOwner]);

	/**
	 * Downloads a screenshot when webl values are ready and the current user is the owner.
	 */
	useEffect(() => {
		// if (currentUserIsTheOwner && weblValuesReady) {
		// 	// Delay the execution by 0.5 seconds
		// 	const timeoutId = setTimeout(() => {
		// 		takeScreenShot(containerRef.current).then(
		// 			(weblThumbnailImg) => {
		// 				axios({
		// 					method: "POST",
		// 					url: "/api/webl/weblid/thumbnail",
		// 					data: { image: weblThumbnailImg, weblid: weblid },
		// 				})
		// 					.then(() => {})
		// 					.catch((error) => {
		// 						console.error(error);
		// 					});
		// 			}
		// 		);
		// 	}, 200);
		// 	return () => clearTimeout(timeoutId);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tookScreenshot]);
	/**
	 * Make the cells with longtext have the same behavior as excel cells
	 * (text overflows untill it meets a cell with value in it)
	 */
	useEffect(() => {
		const table = document.querySelector("table");
		if (table && !fitWidthSwitch) {
			const rows = Array.from(table.querySelectorAll("tr"));
			rows.forEach((row) => {
				const cells = Array.from(row.querySelectorAll("td"));
				let targetCell = null;
				let emptyCellCount = 0;
				let firstHiddenCellFound = false;

				cells.forEach((cell) => {
					const spanElement = cell.querySelector("span");
					const imgElement = spanElement
						? spanElement.querySelector("img")
						: null;

					if (
						cell.style.overflow === "hidden" &&
						cell.textContent.trim().length > 0 &&
						!firstHiddenCellFound &&
						cell.colSpan === 1
					) {
						firstHiddenCellFound = true;
						targetCell = cell;
					}

					if (firstHiddenCellFound) {
						if (
							cell.textContent.trim().length > 0 ||
							(spanElement && imgElement)
						) {
							if (targetCell) {
								targetCell.setAttribute(
									"colspan",
									emptyCellCount + 1
								);
							}
							targetCell = cell;
							emptyCellCount = 0;
						} else {
							emptyCellCount++;
							cell.style.display = "none";
						}
					}
				});

				// The final cell with the long text in every row
				if (targetCell && emptyCellCount > 0) {
					// targetCell.setAttribute("colspan", emptyCellCount + 1);
					targetCell.style.overflow = "visible";
					targetCell.style.whiteSpace = "nowrap";

					let tr = targetCell.parentNode;
					let targetCellIndex = Array.from(tr.cells).indexOf(
						targetCell
					);
					for (let i = targetCellIndex; i < tr.cells.length; i++) {
						let td = tr.cells[i];
						// td.style.overflow = "visible";
						td.style.display = "";
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionInfo, sheetToShow]);
	/**
	 * Function that is building the Webl page. Sets the values from the database to the HTML table.
	 * Takes all the options and themes user enabled and adjusts the webl.
	 */
	const getSpreadsheetValues = useCallback(
		async (sheetToShow) => {
			if (!sessionInfo) {
				return;
			}
			try {
				if (selectedElementsRange !== null && weblid) {
					const sheetElementsRange =
						selectedElementsRange[sheetToShow];

					if (sheetElementsRange.length > 0) {
						if (hideEmptySwitch) {
							//Get the table
							const table = document.querySelector("table");
							// Get all rows in the table
							const rows = table.rows;

							const colgroup = table.querySelector("colgroup");
							const cols = colgroup.querySelectorAll("col");
							let original_table = table.style.width;

							let reducedWidth = parseFloat(original_table);

							//loop throught every cell by column
							for (
								let columnIndex = 0;
								columnIndex < rows[0].cells.length;
								columnIndex++
							) {
								let hasAllHiddenColumnCells = true; // Assume all cells are hidden by default

								for (
									let rowIndex = 0;
									rowIndex < rows.length;
									rowIndex++
								) {
									const cell =
										rows[rowIndex].cells[columnIndex];

									if (
										cell !== undefined &&
										!cell.classList.contains(
											"webl-hidden-cell"
										) &&
										cell.style.visibility !== "hidden" &&
										cell.style.overflow !== "hidden"
									) {
										hasAllHiddenColumnCells = false; // If any cell is not hidden, set to false
										break; // No need to check further for this column
									}
								}

								if (hasAllHiddenColumnCells) {
									// cols[columnIndex].style.width = cols[
									// 	columnIndex
									// ].style.width.replace("pt", "");
									reducedWidth -= parseFloat(
										cols[columnIndex].style.width
									);
									cols[columnIndex].setAttribute(
										"is_hidden",
										"True"
									);
									for (
										let rowIndex = 0;
										rowIndex < rows.length;
										rowIndex++
									) {
										const cell =
											rows[rowIndex].cells[columnIndex];
										if (cell !== undefined) {
											cell.setAttribute(
												"is_hidden",
												"True"
											);
										}
									}
								}
							}
							table.style.width = reducedWidth + "pt";
							//loop throught every cell by row
							for (let i = 0; i < rows.length; i++) {
								let row = rows[i];
								let cells = row.getElementsByTagName("td");
								let hasAllHiddenRowCells = true;

								for (let j = 0; j < cells.length; j++) {
									let cell = cells[j];
									// Check if the cell has a nested table
									let nestedTable =
										cell.querySelector("table");

									if (nestedTable) {
										let nestedCells =
											nestedTable.getElementsByTagName(
												"td"
											);

										if (
											cell.classList.contains(
												"webl-hidden-cell"
											) ||
											cell.style.visibility === "hidden"
										) {
											for (
												let k = 0;
												k < nestedCells.length;
												k++
											) {
												let nestedCell = nestedCells[k];

												nestedCell.style.visibility =
													"hidden";
											}
										}
									}

									if (
										!cell.classList.contains(
											"webl-hidden-cell"
										) &&
										cell.style.visibility !== "hidden" &&
										cell.style.overflow !== "hidden"
									) {
										hasAllHiddenRowCells = false;
										break;
									}
								}

								// If all cells in the row are hidden or not selected:
								if (hasAllHiddenRowCells) {
									row.setAttribute("is_hidden", "True");
								}
							}
						}
						// get dimensions of the current sheet
						const [sheetMinRow, sheetMaxRow] =
							initialElementsRange[sheetToShow][0]["row"];
						const [sheetMinColumn, sheetMaxColumn] =
							initialElementsRange[sheetToShow][0]["column"];
						const rangeAddress =
							numberToCol(sheetMinColumn) +
							(sheetMinRow + 1) +
							":" +
							numberToCol(sheetMaxColumn) +
							(sheetMaxRow + 1);

						// getting the values of the current sheet from the file in OneDrive (it has already been updated)
						const sheetValues = await getRangeValue(
							sessionInfo,
							weblid,
							sheetNamesDictionary[sheetNames[sheetToShow]],
							rangeAddress
						);

						// getting the names of the charts of the current sheet from the file in OneDrive
						let chartNames = [];
						let charts = {};

						if (chartData.length !== 0) {
							chartNames = await getChartNames(
								sessionInfo,
								weblid,
								sheetNamesDictionary[sheetNames[sheetToShow]]
							);
							for (let i = 0; i < chartNames.length; i++) {
								charts[chartNames[i]] = await getChartImage(
									sessionInfo,
									weblid,
									sheetNamesDictionary[
									sheetNames[sheetToShow]
									],
									chartNames[i]
								);
							}
						}
						const weblHtml =
							document.getElementsByClassName("webl-html")[0];
						weblHtml.setAttribute("webl_theme", weblTheme);
						const table = document.querySelector("table");
						if (fitWidthSwitch) {
							table.setAttribute("fit_width", fitWidthSwitch);
						}
						table.setAttribute("webl_theme", weblTheme);
						table.setAttribute(
							"table_spacing",
							initialThemeSettings.tableSpacing
						);
						table.setAttribute(
							"gridlines",
							initialThemeSettings.gridlines
						);
						table.setAttribute(
							"round_borders",
							initialThemeSettings.roundBorders
						);
						// getting all the cells / tds and we visit them
						const cells = document.querySelectorAll("td");
						for (
							let c_table = 0, cellCount = cells.length;
							c_table < cellCount;
							c_table++
						) {
							const cell = cells[c_table];
							const r_sheet = parseInt(cell.id.split("_")[0]);
							const c_sheet = parseInt(cell.id.split("_")[1]);
							cell.setAttribute(
								"input_borders",
								initialThemeSettings.inputBorders
							);
							cell.setAttribute(
								"round_borders",
								initialThemeSettings.roundBorders
							);
							cell.setAttribute(
								"gridlines",
								initialThemeSettings.gridlines
							);
							cell.setAttribute(
								"table_spacing",
								initialThemeSettings.tableSpacing
							);

							// if its a cell with content that's not hidden
							if (
								r_sheet < sheetValues.length &&
								typeof sheetValues[r_sheet][c_sheet] !==
								"undefined" &&
								cell.style.visibility !== "hidden"
							) {
								// we check if it is a graphic element (image or chart)
								if (
									cell.getElementsByTagName("img").length > 0
								) {
									// we check if it is inside the display area
									const cellInsideSelectedRange =
										selectedElementsRange[
											sheetToShow
										].filter(
											(range) =>
												range.row[0] <= r_sheet &&
												range.row[1] >= r_sheet &&
												range.column[0] <= c_sheet &&
												range.column[1] >= c_sheet
										).length > 0;
									// const image =
									// 	cell.getElementsByTagName("img")[0];

									cell.getElementsByTagName("img").forEach(
										async (image) => {
											const name =
												image.getAttribute("name");
											const src = image.src
												.split("/")
												.slice(-1)[0];
											if (cellInsideSelectedRange) {
												//NOTE: 'fix' for USA demograpgics spreadsheet name sometimes was null
												if (
													name !== null &&
													(src.startsWith("chart") ||
														name in charts ||
														name.replace(
															"Chart",
															"Chart "
														) in charts)
												) {
													if (
														typeof charts[name] !==
														"undefined"
													) {
														image.src = `data:image/png;base64,${charts[name]}`;
													} else {
														// sometimes the html changed the chart name from "Chart 1" to "Chart1"
														// so we test this case now
														image.src = `data:image/png;base64,${charts[
															name.replace(
																"Chart",
																"Chart "
															)
														]
															}`;
													}
												} else if (
													src.endsWith(".png") ||
													src.endsWith(".jpg") ||
													src.endsWith(".jpeg") ||
													src.endsWith(".gif")
												) {
													let formData =
														new FormData();
													formData.append(
														"weblid",
														weblid
													);
													formData.append(
														"image",
														src
													);
													// we setup a dictionary with cell ids and image source names, because after the src got overwritten with
													// the most recent src, we will lose the a
													image.src = await axios({
														method: "GET",
														url: `/api/webl/${weblid}/image/${src}`,
														data: formData,
													})
														.then((response) => {
															const imageResponse =
																response.data.image_data;
															if (
																src.endsWith(
																	"png"
																)
															) {
																return `data:image/png;base64,${imageResponse}`;
															} else if (
																src.endsWith(
																	"jpg"
																)
															) {
																return `data:image/jpg;base64,${imageResponse}`;
															} else if (
																src.endsWith(
																	"jpeg"
																)
															) {
																return `data:image/jpeg;base64,${imageResponse}`;
															} else if (
																src.endsWith(
																	"gif"
																)
															) {
																return `data:image/gif;base64,${imageResponse}`;
															}
														})
														.catch((error) => {
															if (
																error.response
															) {
																// The client was given an error response (5xx, 4xx)
																console.error(
																	error
																		.response
																		.status +
																	" " +
																	error
																		.response
																		.statusText
																);

																if (
																	error
																		.response
																		.status ===
																	404
																) {
																	navigate(
																		baseUrl + "/error"
																	);
																} else {
																	// Display a user-friendly error message for other errors
																	alert(
																		"An error occurred. Please try again later."
																	);
																}
															} else if (
																error.request
															) {
																// The client never received a response, and the request was never left
																console.error(
																	error.request
																);
																alert(
																	"An error occurred. Please check your internet connection."
																);
															} else {
																// Anything else
																console.error(
																	"error",
																	error.message
																);

																if (
																	error instanceof
																	GraphError
																) {
																	if (
																		error.statusCode ===
																		404
																	) {
																		navigate(
																			baseUrl + "/error"
																		);
																	}
																} else {
																	alert(
																		"An unexpected error occurred. Please try again later."
																	);
																}
															}
														});
												}
											} else {
												image.remove();
											}
										}
									);

									for (
										let i = 0;
										i < cell.children.length;
										i++
									) {
										const child = cell.children[i];
										// Check if the child has children
										if (child.children.length > 0) {
											for (
												let j = 0;
												j < child.children.length;
												j++
											) {
												const grandChild =
													child.children[j];
												// Check if the grandChild is a table element
												if (
													grandChild.tagName.toLowerCase() ===
													"table"
												) {
													const innerTd =
														grandChild.querySelector(
															"td"
														);
													const innerTdStyle =
														window.getComputedStyle(
															innerTd
														);

													cell.style.background =
														innerTdStyle.background;
													break;
												}
											}
										}
									}
								} else if (
									// if it is a cell with dropdown/data validation
									Object.keys(dropdowns).includes(
										sheetToShow.toString()
									) &&
									dropdowns[sheetToShow]
										.map((dropdown) => dropdown["cell"])
										.includes(cell.id)
								) {
									const dropdown = dropdowns[
										sheetToShow
									].filter(
										(dropdownSheet) =>
											dropdownSheet["cell"] === cell.id
									)[0];
									let options;
									if (
										Object.keys(dropdown).includes("range")
									) {
										let range;
										let sheet;
										if (dropdown["range"].includes("!")) {
											sheet =
												dropdown["range"].split("!")[0];
											range =
												dropdown["range"].split("!")[1];
										} else {
											range = dropdown["range"];
											sheet = sheetNames[sheetToShow];
										}
										options = await getRangeValue(
											sessionInfo,
											weblid,
											sheetNamesDictionary[sheet],
											range
										);
									} else {
										options = dropdown["values"].split(",");
									}

									options = options.flat();

									const selectElement = (
										<select
											id={cell.id + "_select"}
											className={cell.className}
											style={{
												width: "100%",
												height: cell.parentNode.style
													.height,
											}}
											defaultValue={
												sheetValues[r_sheet][c_sheet]
											}
										>
											{sheetValues[r_sheet][c_sheet] ===
												"\u00A0" && (
													<option
														disabled
														value={"\u00A0"}
													>
														{"\u00A0"}
													</option>
												)}
											{sheetValues[r_sheet][c_sheet] ===
												"" && (
													<option disabled value={""}>
														{""}
													</option>
												)}
											{options.map((option, index) => (
												<option
													key={`${option}-${index}`}
													value={option}
												>
													{option}
												</option>
											))}
										</select>
									);
									cell.setAttribute("webl_theme", weblTheme);
									ReactDOM.render(selectElement, cell);
								} else {
									let currentCell = cell;
									if (
										currentCell.innerHTML.startsWith("<a")
									) {
										// If the cell content starts with <a, assume it's an anchor element
										const tempElement =
											document.createElement("div");
										tempElement.innerHTML =
											currentCell.innerHTML;

										if (tempElement.querySelector("span")) {
											// If there's a <span> element inside the <a> element, use it as currentCell
											currentCell =
												tempElement.querySelector(
													"span"
												);
										} else {
											// Otherwise, use the <a> element itself
											currentCell = tempElement;
										}
									}
									currentCell.setAttribute(
										"webl_theme",
										weblTheme
									);
									if (
										manuallyChangedCell.current
											.sheetToShow !== null &&
										manuallyChangedCell.current.sheet !==
										null &&
										realTimeSwitch
									) {
										if (
											manuallyChangedCell.current.inputId.split(
												"_"
											)[0] === r_sheet.toString() &&
											manuallyChangedCell.current.inputId.split(
												"_"
											)[1] === c_sheet.toString() &&
											manuallyChangedCell.current
												.sheet === sheetToShow
										) {
											continue;
										}
									}
									currentCell.textContent =
										sheetValues[r_sheet][c_sheet];
								}
							} else {
								// if th cell is not supposed to be seen, we erased its content to be also hidden from the source code
								// cell.textContent = "";
							}
						}
					}

					setWeblValuesReady(true); // this variable checks that everything is initialised, in order to check this with only one variable and not many different.
					if (!tookScreenshot) {
						setTookScreenshot(true);
					}
					setLoading(false);
				}
			} catch (error) {
				if (error.response) {
					// The client was given an error response (5xx, 4xx)
					console.error(
						error.response.status + " " + error.response.statusText
					);

					if (error.response.status === 404) {
						navigate(baseUrl + "/error");
					} else {
						// Display a user-friendly error message for other errors
						alert("An error occurred. Please try again later.");
					}
				} else if (error.request) {
					// The client never received a response, and the request was never left
					console.error(error.request);
					alert(
						"An error occurred. Please check your internet connection."
					);
				} else {
					// Anything else
					console.error("error", error.message);

					if (error instanceof GraphError) {
						if (error.statusCode === 404) {
							navigate(baseUrl + "/error");
						}
					} else {
						alert(
							"An unexpected error occurred. Please try again later."
						);
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			sessionInfo,
			initialElementsRange,
			selectedElementsRange,
			sheetNames,
			weblid,
		]
	);

	/**
	 * A useEffect that handles all cells that were marked as input cells and makes them editable.
	 */
	useEffect(() => {
		const inputs = document.querySelectorAll('[contenteditable="True"]');
		if (inputs !== null && typeof realTimeSwitch === "boolean") {
			for (let i = 0; i < inputs.length; i++) {
				inputs[i].addEventListener("focus", (e) => handleFocusInput(e));
				if (realTimeSwitch) {
					let searhtimer;
					inputs[i].addEventListener("input", (e) => {
						clearTimeout(searhtimer);
						searhtimer = setTimeout(() => {
							handleChangeInput(e, inputs[i]["id"]);
						}, 700);
						manuallyChangedCell.current = {
							inputId: inputs[i].id,
							sheet: sheetToShow,
						};
					});
				} else {
					inputs[i].addEventListener("blur", (e) => {
						handleChangeInput(e, inputs[i]["id"]);
					});
				};
			}
		}

		if (dropdowns !== undefined) {
			if (Object.keys(dropdowns).includes(sheetToShow.toString())) {
				dropdowns[sheetToShow].forEach((dropdown) => {
					const element = document.getElementById(
						dropdown["cell"] + "_select"
					);

					if (element !== null) {
						element.addEventListener("change", (e) =>
							handleChangeDropdowns(e, dropdown["cell"])
						);
					}
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		spreadsheetHtml,
		sheetToShow,
		weblValuesReady,
		getSpreadsheetValues,
		sessionInfo,
		sheetNames,
		weblid,
	]);
	/**
	 * This functions handles changes in downdown cells
	 */
	const handleChangeDropdowns = async (e, cellId) => {
		setLoading(true);
		e.preventDefault();
		await setCellValue(
			sessionInfo,
			weblid,
			sheetNamesDictionary[sheetNames[sheetToShow]],
			cellId.split("_")[0],
			cellId.split("_")[1],
			e.target.value,
			weblid
		);
		await getSpreadsheetValues(sheetToShow);
		setLoading(false);
	};
	/**
	 * This function handles the changes in input cells
	 */
	const handleChangeInput = async (e, id) => {
		setLoading(true);
		e.preventDefault();
		const row = id.split("_")[0];
		const col = id.split("_")[1];
		let value = e.target.textContent;

		const inputElement = e.target;
		// Disable the input while loading (for webl without realmTimeSwitch)
		if (!realTimeSwitch) {
			inputElement.setAttribute("contenteditable", "False");
		}
		if (value !== inputFocusValueRef.current) {
			sessionInfo &&
				(await setCellValue(
					sessionInfo,
					weblid,
					sheetNamesDictionary[sheetNames[sheetToShow]],
					row,
					col,
					value,
					weblid
				));
			await getSpreadsheetValues(sheetToShow);
			setLoading(false);
			inputElement.setAttribute("contenteditable", "True");
		} else {
			// Set loading bar false if user clicks input cell and clicks out
			setLoading(false);
			inputElement.setAttribute("contenteditable", "True");
		}
	};
	/**
	 * sets focused input's cell value to a variable
	 */
	const handleFocusInput = (e) => {
		setInputFocusValue(e.target.outerText);
		inputFocusValueRef.current = e.target.outerText;
	};

	// The interval for keepSessionAlive has been moved into a single call useEffect to
	// resolve an outstanding issue, where before it would be called several times through a 
	// react refresh, resulting with redundant intervals running in the background. 
	useEffect(() => {
		/**
		 * Keeps the session alive
		*/
		setInterval(() => {
			if (sheetNames.length > 0 && sheetToShow !== null) {
				sessionInfo &&
					keepSessionAlive(
						sessionInfo,
						weblid,
						sheetNamesDictionary[sheetNames[sheetToShow]]
					);
			}
		}, 5 * 60 * 1000); // Send the request every 5 minutes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Create a session for the webl
	 */
	useEffect(() => {
		weblid &&
			peristentChanges !== null &&
			createSession(setSessionInfo, weblid, peristentChanges);
		return () => {
			try {
				sessionInfo && weblid && closeSession(sessionInfo, weblid);
			} catch (e) {
				process.env.NODE_ENV === "development" &&
					console.error("Can't close session", e);
			}
		}; // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weblid, peristentChanges]);

	/**
	 * Axios POST to fetch the data from the server
	 */
	useEffect(() => {
		setBoxedContainer(weblData.boxed_container);
		setCustomScrollbar(weblData.custom_scrollbar);
		setSheetNames(
			JSON.parse(weblData.content).map((obj) => obj.name)
		);
		if (typeof user === "undefined") {
			setCurrentUserIsTheOwner(false);
		} else {
			setCurrentUserIsTheOwner(weblData.userId === user.sub);
		}
		setChartData(JSON.parse(weblData.charts));
		setInitialElementsRange(
			JSON.parse(weblData.suggested_elements_range)
		);
		setZoomWebl(weblData.zoom_webl);
		setSpreadsheetHtml(JSON.parse(weblData.html));

		setFitWidthSwitch(weblData.fit_width);
		setHideEmptySwitch(weblData.hide_empty);

		setSheetsVisibility(
			JSON.parse(weblData.elements_range).map(
				(sheetArray) => sheetArray.length > 0
			)
		);
		setSheetToShow(
			JSON.parse(weblData.elements_range).findIndex(
				(subarray) => subarray.length !== 0
			)
		);
		setSelectedElementsRange(
			JSON.parse(weblData.elements_range)
		);
		setDownloadableSpreadsheet(
			weblData.downloadable_spreadsheet
		);
		setPersistentChanges(weblData.persistent_changes);
		setWeblTheme(weblData.theme);
		setInitialThemeSettings(
			JSON.parse(weblData.initial_theme_settings)
		);
		setCenteredLayout(weblData.centered_layout);
		setRealTimeSwitch(weblData.real_time_switch);
		setDropdowns(
			cleanDropdowns(
				JSON.parse(weblData.input_range),
				JSON.parse(weblData.dropdowns)
			)
		);

		setPublicAccess(weblData.webl_status);
		setSheetNamesDictionary(
			JSON.parse(weblData.sheet_names_dictionary)
		);
		setRemoveIframeCode(weblData.iframe_code);
		const iframeDimensions = JSON.parse(
			weblData.iframe_dimensions
		);

		setIframeComponent(
			`<iframe 
							src="//${subdomain}.${rootHost}"
							title="${weblData.title}"
							frameborder="0"
							style="width: ${iframeDimensions.width}"; height: "${iframeDimensions.height};"
						></iframe>'`
		);

	}, [rootHost, subdomain, user, weblData]);

	/**
	 * Calls the getSpreadsheetValues function everytime we change sheet
	 */
	useEffect(() => {
		getSpreadsheetValues(sheetToShow);
	}, [getSpreadsheetValues, sheetToShow]);

	/**
	 * Browser back button behavior.
	 * Clarification: You can't change the behavior of the Browser Back Button.
	 * Workaround: check if the last location visited in the application is /weblCreation (the e.state.usr exists)
	 * if it does window.location.href to sepcific place.
	 * NOT A GOOD PRACTICE
	 */
	window.onpopstate = (e) => {
		const previousURL = e.state.usr;
		if (previousURL !== undefined) {
			window.location.href = baseUrl + "/updateWebl/" + subdomain;
		}
	};

	/**
	 * Function that handles the delete webl button (confirmation of the popup window)
	 */
	const deleteWebl = (weblid = null) => {
		!weblid && (weblid = weblToDelete);
		axios({
			method: "DELETE",
			url: baseUrl + "/api/webl/" + weblid,
		})
			.then(() => {
				deleteFile(weblid);
			})
			.catch((error) => {
				if (error.response) {
					// The client was given an error response (5xx, 4xx)
					console.error(
						error.response.status + " " + error.response.statusText
					);

					if (error.response.status === 404) {
						navigate(baseUrl + "/error");
					} else {
						// Display a user-friendly error message for other errors
						alert("An error occurred. Please try again later.");
					}
				} else if (error.request) {
					// The client never received a response, and the request was never left
					console.error(error.request);
					alert(
						"An error occurred. Please check your internet connection."
					);
				} else {
					// Anything else
					console.error("error", error.message);

					if (error instanceof GraphError) {
						if (error.statusCode === 404) {
							navigate(baseUrl + "/error");
						}
					} else {
						alert(
							"An unexpected error occurred. Please try again later."
						);
					}
				}
			});
		setWeblToDelete(0);
	};

	/**
	 * Handles the edit webl button.
	 * Navigates back to the WeblCreation page with the changes made (if the persistent changes is enabled)
	 */
	const handleEditWebl = () => {
		navigate(baseUrl + "/updateWebl/" + subdomain);
	};

	return (
		<>
			<WeblManager
				sheetNames={sheetNames}
				sheetsVisibility={sheetsVisibility}
				sheetToShow={sheetToShow}
				setWeblValuesReady={setWeblValuesReady}
				setSheetToShow={setSheetToShow}
				downloadableSpreadsheet={downloadableSpreadsheet}
				sessionInfo={sessionInfo}
				weblid={weblid}
				weblTitle={weblTitle}
				currentUserIsTheOwner={currentUserIsTheOwner}
				handleEditWebl={handleEditWebl}
				setWeblToDelete={setWeblToDelete}
				weblToDelete={weblToDelete}
				deleteWebl={deleteWebl}
				navigate={navigate}
				iframeComponent={iframeComponent}
				previewMode={previewMode}
				removeIframeCode={removeIframeCode}
			/>
			{loading && <div className="loading-bar" />}
			{publicAccess === "iframe" && (
				<RestrictedWebl weblDomain={subdomain} />
			)}
			{sheetToShow !== null && (
				<WeblContent
					weblValuesReady={weblValuesReady}
					spreadsheetHtml={spreadsheetHtml}
					sheetToShow={sheetToShow}
					centeredLayout={centeredLayout}
					selectedElementsRange={selectedElementsRange}
					containerRef={containerRef}
					zoomWebl={zoomWebl}
					boxedContainer={boxedContainer}
					customScrollbar={customScrollbar}
				/>
			)}
		</>
	);
}

export default Webl;
