import { useState, useEffect } from "react";
import "../../css/Home/Home.css";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationDialog from "../ConfirmationDialog.jsx";
import LoadingAnimation from "../LoadingAnimation.jsx";
import { axios } from "../../libs/axios.js";
import { storage } from "../../functions/utils.js";

function ManageAccount({ userType }) {

	const [showDeleteWarning, setShowDeleteWarning] = useState(false);
	const [loading, setLoading] = useState(false);
	const { user, isLoading } = useAuth0();
	const { logout } = useAuth0();
	const [userCountry, setUserCountry] = useState(null);



	useEffect(() => {
		if (!isLoading) {
			axios.get("/api/auth/user").then((response) => {
				setUserCountry({
					country: response.data.country,
					continent: response.data.continent,
				});
			});
		}
	}, [isLoading]);

	return (
		<div id="manage-account-container">
			<div style={{ fontSize: 36, marginBottom: 18 }}>
				<strong>
					{userType
						.replace(/_/g, " ")
						.replace(/\b\w/g, (match) => match.toUpperCase())}
				</strong>{" "}
				plan
			</div>
			{!loading && (
				<>
					<div className="links">
						{userCountry && userCountry.continent && (
							<>
								{userType === "free" ? (
									<a
										href="/pricing"
										target="_blank"
										rel="noopener noreferrer"
										className="manage-account-option"
									>
										&gt; Upgrade account to use premium
										features
									</a>
								) : (
									<a
										href={
											userCountry.country ===
												"United Kingdom"
												? "https://billing.stripe.com/p/login/test_3cs8yU7dYbt66acbII"
												: "https://billing.stripe.com/p/login/test_28o7tl87F5U883CbII"
										}
										target="_blank"
										rel="noopener noreferrer"
										className="manage-account-option"
									>
										&gt; Edit current subscription
									</a>
								)}
							</>
						)}

						<div
							className="manage-account-option"
							onClick={() => setShowDeleteWarning(true)}
						>
							&gt; Delete account
						</div>

					</div>
				</>
			)}

			<ConfirmationDialog
				open={showDeleteWarning === true}
				onClose={() => setShowDeleteWarning(false)}
				dialogText="This action is going to permantly delete your account, settings and all webls associated with it. Any active subscription will be terminated. Are you sure you want to continue?"
				confirmAction={() => {
					setLoading(true);
					setShowDeleteWarning(false);
					axios({
						method: "POST",
						url: "/api/auth/user",
						data: {
							userid: user.sub,
						},
					}).then((response) => {
						logout({
							returnTo: "/",
						});
						storage.clearToken();
						setLoading(false);
					});
				}}
				cancelAction={() => setShowDeleteWarning(false)}
			/>
			{loading && (
				<LoadingAnimation
					descTextLine1="Deleting your account..."
					descTextLine2="Bye bye Mr Weblerer!"
				/>
			)}
		</div>
	);
}

export default ManageAccount;
