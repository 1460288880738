import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React from 'react';
import { cn } from '../libs/utils';
import Heading from '../Typography/Heading';

export interface IModalComponentProps {
    title?: string | React.ReactNode;
    children?: React.ReactNode;
    open: boolean;
    setOpen: (state: boolean) => void;
    className?: string;
    cypressTag?: string;
    hideClose?: boolean;
}

export default function ModalComponent({
    title,
    children,
    open = false,
    setOpen,
    className,
    cypressTag,
    hideClose = false,
}: IModalComponentProps) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                data-cy={cypressTag}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex justify-center items-center"
            >
                <div className="relative text-primary">
                    {!hideClose && (
                        <IconButton
                            onClick={handleClose}
                            className="absolute top-5 right-5 text-pr z-20"
                        >
                            <CloseIcon className="text-4xl" />
                        </IconButton>
                    )}
                    <div
                        className={cn(
                            'bg-white w-full sm:min-w-[400px] max-w-[600px] p-8 shadow-lg rounded-lg',
                            className
                        )}
                    >
                        {title && (
                            <Heading className="text-center text-2xl font-bold text-[#3f5645]">
                                {title}
                            </Heading>
                        )}
                        <div className="mt-4">
                            {children}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
