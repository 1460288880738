import { Tooltip } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import "../../css/WeblCreation/Inputs.css";
import { axios } from "../../libs/axios";

function Inputs({
	weblTitle,
	setWeblTitle,
	weblTitles,
	UnavailableIcon,
	AvailableIcon,
	weblDomain,
	setWeblDomain,
	subDirectories,
}) {
	const inputRef = useRef(null);
	const domainRef = useRef(null);

	const [titleDebounceTimeout, setTitleDebounceTimeout] = useState(null);
	const [domainDebounceTimeout, setDomainDebounceTimeout] = useState(null);

	const [isTitleAvailable, setIsTitleAvailable] = useState(true);
	const [isDomainAvailable, setIsDomainAvailable] = useState(true);
	const [domainErrorMessage, setDomainErrorMessage] = useState(""); // For domain error messages
	const [titleErrorMessage, setTitleErrorMessage] = useState(""); // For title error messages

    // Check Webl title availability with axios
	const checkWeblTitleAvailability = async (title) => {
		if (title) {
			try {
				const response = await axios.get('/api/webl/validate/weblTitle', { params: { weblTitle: title } });

				setIsTitleAvailable(response.data.valid); // Update title availability state
				// Set title error message if there are any validation errors
				if (!response.data.valid) {
					setTitleErrorMessage(response.data.error.join(", ")); // Show all validation reasons
				} else {
					setTitleErrorMessage(""); // Clear error if valid
				}
			} catch (error) {
				console.error('Error validating title:', error);
				setTitleErrorMessage("Error validating title."); // Handle error case
			}
		} else {
			setTitleErrorMessage(""); // Clear error if title is empty
		}
	};

  // Check Webl domain availability with axios
	const checkWeblDomainAvailability = async (domain) => {
		if (domain) {
			try {
				const response = await axios.get('/api/webl/validate/weblDomain', { params: { weblDomain: domain } });

				setIsDomainAvailable(response.data.valid); // Update domain availability state
				
				// Set error message based on backend validation
				if (!response.data.valid) {
					setDomainErrorMessage(response.data.error.join(", ")); // Show all validation reasons
				} else {
					setDomainErrorMessage(""); // Clear error if valid
				}
			} catch (error) {
				console.error('Error validating domain:', error);
				setDomainErrorMessage("Error validating domain."); // Handle error case
			}
		} else {
			setDomainErrorMessage(""); // Clear error if domain is empty
		}
	};

	// Set up the interval to validate title and domain every 15 seconds
	useEffect(() => {
		const interval = setInterval(() => {
			checkWeblTitleAvailability();
			checkWeblDomainAvailability();
		}, 15000);

		// Clean up the interval on component unmount
		return () => clearInterval(interval);
	}, [weblTitle, weblDomain]);

	const handleClick = (e) => {
		const elementTitleAvailable = document.getElementById("webl-title-availability");
		elementTitleAvailable.style.visibility = "visible";

		const elementDomainAvailable = document.getElementById("webl-domain-availability");
		elementDomainAvailable.style.visibility = "visible";
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, []);

	// Debounce function for title input
	const handleTitleChange = (e) => {
		const { value } = e.target;
		setWeblTitle(value);

		// Clear previous timeout if it exists
		if (titleDebounceTimeout) clearTimeout(titleDebounceTimeout);

		// Wait 0.5 seconds before checking title availability
		const newTimeout = setTimeout(() => {
			checkWeblTitleAvailability(value);
		}, 500);

		setTitleDebounceTimeout(newTimeout);
	};

	// Debounce function for domain input
	const handleDomainChange = (e) => {
		const { value } = e.target;
		setWeblDomain(value);

		// Clear previous timeout if it exists
		if (domainDebounceTimeout) clearTimeout(domainDebounceTimeout);

		// Wait 0.5 seconds before checking domain availability
		const newTimeout = setTimeout(() => {
			checkWeblDomainAvailability(value);
		}, 500);

		setDomainDebounceTimeout(newTimeout);
	};

	return (
		<div id="inputs-container">
			<div id="webl-title-container">
				<input
					id="webl-title"
					type="text"
					value={weblTitle}
					placeholder="Give your Webl a title"
					onChange={handleTitleChange}
					ref={inputRef}
					maxLength="25"
				/>
				<div id="webl-title-availability">
					{weblTitle && (
						<Tooltip
							title={titleErrorMessage || (isTitleAvailable ? weblTitle + " is available." : "Webl title already exists.")}
						>

							<img
								className="webl-title-icon"
								src={isTitleAvailable ? AvailableIcon : UnavailableIcon}
								alt="Title Availability Icon"
							/>
						</Tooltip>
					)}
				</div>
			</div>
			<div id="webl-domain-container">
				<input
					id="webl-domain"
					type="text"
					value={weblDomain}
					placeholder="Give your Webl a custom website path"
					onChange={handleDomainChange}
					maxLength="30"
					ref={domainRef}
				/>
				<div id="webl-domain-availability">

					<Tooltip
						title={domainErrorMessage || (isDomainAvailable ? weblDomain + " is available." : "Webl domain already exists.")}
					>

						<img
							className="webl-title-icon"
							src={isDomainAvailable ? AvailableIcon : UnavailableIcon}
							alt="Domain Availability Icon"
						/>
					</Tooltip>

				</div>
			</div>
		</div>
	);

}

export default Inputs;
