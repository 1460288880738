import { Alert } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';

export interface INotificationProps {
    severity: 'error' | 'success' | 'info' | 'warning';
    variant: 'filled' | 'outlined';
    message: string;
    className?: string;
}

export function Notification({ severity, variant, message, className }: INotificationProps) {
    return (
        <div className={className}>
            <Alert severity={severity} variant={variant}>
                {message}
            </Alert>
        </div>
    );
}

export function showNotification({ severity, variant, message }: INotificationProps) {
    toast.custom(
        (t) => (
            <Notification
                className={t.visible ? 'animate-fade-down' : 'animate-fade-down animate-reverse'}
                severity={severity}
                variant={variant}
                message={message}
            />
        ),
        {
            id: 'notifcation-toast',
        }
    );
}
