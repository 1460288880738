import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "../css/Home/Home.css";
import "../css/Home/HomeScrollbar.css";
import CornerIcon from "../images/icons/corner.svg";
import HomeFooter from "../components/Home/HomeFooter.jsx";
import HomeHeader from "../components/Home/HomeHeader.jsx";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { storage } from "../functions/utils.js";

export default function ErrorPage() {
	const [errorType, setErrorType] = useState(useParams()["errorType"]);
	const [redirectioncount, setRedirectionCount] = useState(0);
	const redirectionAfter = 5; // seconds
	const navigate = useNavigate();
	const errorMessage = {
		notFound: {
			title: "Page not found",
			description: "Sorry, the page you're looking for does not exist.",
		},
		noVerifiedEmail: {
			title: "Unverified email",
			description: "Please verify your email before logging in.",
		},
		unavailableWebl: {
			title: "This Webl isn't available anymore",
			description: "Redirecting to the home page...",
		},
		somethingWentWrong: {
			title: "Something went wrong",
			description: "Redirecting to the home page...",
		},
	};
	const { logout } = useAuth0();

	useEffect(() => {
		console.error(errorType);
		if (!Object.keys(errorMessage).includes(errorType)) {
			setErrorType("somethingWentWrong");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorType]);

	useEffect(() => {
		if (
			redirectioncount === redirectionAfter &&
			(errorType === "unavailableWebl" ||
				errorType === "somethingWentWrong")
		) {
			navigate("/");
			return;
		}
		const timer = setInterval(() => {
			redirectioncount <= redirectionAfter &&
				setRedirectionCount((prevCount) => prevCount + 1);
		}, 1000);
		return () => clearInterval(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectioncount]);

	return (
		<div id="home-page">
			<HomeHeader />
			<div id="home-container">
				{Object.keys(errorMessage).includes(errorType) && (
					<>
						<div id="home-outline-top">
							<div id="home-outline-top-left">
								<img src={CornerIcon} alt="corner-icon" />
							</div>
							<div id="home-outline-top-right" />
						</div>
						<div id="home-container-content">
							<div id="error-container">
								<div className="error-title">
									{errorMessage[errorType].title}
								</div>
								<div className="error-description">
									{errorMessage[errorType].description}
								</div>
								{errorType === "noVerifiedEmail" && (
									<div
										id="log-in-verification"
										onClick={() => {
											logout({
												returnTo: "/",
											});
											storage.clearToken();
										}}
									>
										Click here to login
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</div>
			<HomeFooter />
		</div>
	);
}
