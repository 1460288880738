function fetchDomain() {
    const { hostname, port } = window.location;
    const parts = hostname.split('.');
    const subdomain = parts.shift();
    const rootHost = `${parts.join('.')}${port ? `:${port}` : ''}`;
    parts.unshift("app");
    let updatedHostname = parts.join('.');
    if (hostname.includes("localhost")) {
      updatedHostname += port ? `:${port}` : '';
    }
    const baseUrl = `//${updatedHostname}`;
  
    return {
      SUBDOMAIN: subdomain,
      HOST: updatedHostname,
      ROOT_HOST: rootHost,
      BASE_URL: baseUrl
    };
  }
  
  export const { SUBDOMAIN, HOST, ROOT_HOST, BASE_URL } = fetchDomain();



export const storage = {
  getToken: () => {
    try{
      return window.localStorage.getItem("webler_token");
    }catch(e){
      return undefined
    }
  },
  setToken: (token) => {
    if (token) {
      window.localStorage.setItem("webler_token", token);
    }
  },
  clearToken: () => {
    window.localStorage.removeItem("webler_token");
  },

  getCSRFToken: () => {
    try{
      return window.localStorage.getItem("webler_csrf");
    }catch(e){
      return undefined
    }
  },
  setCSRFToken: (token) => {
    if (token) {
      window.localStorage.setItem("webler_csrf", token);
    }
  },
};
